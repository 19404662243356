import PIXI from '../../lib/PixiProjectionExport.js'
import * as TrackPath from '../Track/TrackPath.js'
import '@pixi/math-extras'
import * as Hf from '../../helperfunctions/HelperFunctions.js'
import * as Minimap from '../Minimap/Minimap.js'
import { initTiles } from '../World/TileController.js'
import { GameSettings } from '../../settings/GameSettings.js'
import * as TexturePackerLoader from '../TexturePackerLoader/TexturePackerLoader.js'
import * as WorldObjectController from '../World/WorldObjectController.js'
import * as GameUI from '../GameUI/GameUI.js'
import {
    initBackground,
    updateBackground,
} from '../World/BackgroundController.js'
import * as InputManager from './InputManager.js'
import * as TrackCollision from '../Track/TrackCollision.js'
import { PixelateFilter } from '@pixi/filter-pixelate'
import { ZoomBlurFilter } from '@pixi/filter-zoom-blur'
import * as GameOverOverlay from '../Overlays/GameOverOverlay'
import { updateSpeed } from './InputManager.js'
import * as DrsManager from './DrsManager'
import * as ParticleContainer from '../particleeffects/particleContainer'
import { GlobalData } from '../../../data/GlobalData'
import * as PerformanceManager from '../PerformanceManager/PerformanceManager.js'
import * as LoadingScreen from '../Loader/LoadingScreen'
import * as GameMetrics from './GameMetrics.js'
import { trackRoadPoints } from '../../../public/assets/gamedata/TrackData.js'
import Replay from './Replay.js'

//---App refs
let pixiContainer //HTML/svelte thing
let app //PIXI.Application
let width

//---Speed
export let speed = 1

//---Container refs
let gameSceneContainer
let loadingContainer
let groundPlane
let camera
let carSprite
let carGhost
let carShadowSprite

//Update vars:
let deltaTime
export let elapsed = 0.0;
export let elapsedFromStart = 0.0

//---Anim vars
let lastCarWheelAnimCycle = 0
let carWheelAnimCycle = false
let carAnimIndex = 0

//---DRS rocket boost >.> filter
let drsZoomBlurFilter
let drsFilterT = 0

let startBoostFilterT = 0

//---Respawn fade vars
let respawnFilter
let isFadingOut = false,
    isFadingIn = false
let fadeT = 0
let newCarLoc
let isDead = false

//---Location info
let lastCarRot = 0
let cachedLoc
let nearestTrackLoc
let startLoc

let isOnTrack = true

//---Cached data
let carScale
let carGhostScale
let carTex
let carGhostTex

//---DEBUG
let debugSpriteA
let debugSpriteB

//---HTML DEBUG Sliders
let sliderValues = []
let sliderElements = []
let sliderTooltips = []

let logA
let canvas
let uiContainer

//---State checks
let gameStarted = false
let passedStart = false
let gameOverShown = false
let gameOverSent = false
let didNotFinish = false
let doCarLocationReset = true
let teleportToStart = false
let tutorialVisible = false
let tutorialStarted = false
let appTickerAdded = false
let countDownDone = false
let canBrakeTutorial = true
let canGasTutorial = true
let canDrsTutorial = false
let soundLoaded = false

//---To reset values
let initialConstantSpeed
let pausedSpeed = 0

let loadingInterval

//--- Get sound engine (this is a .svelte file)
let soundEngine

let finishTime

let history = []
let replay = Replay.hist;
let gohostCrrPos;

//---Build function from GameInstance.svelte to here so I can use browser debugger
export function buildGameInstance(pixiContainerRef, soundEngineRef) {
    pixiContainer = pixiContainerRef
    soundEngine = soundEngineRef
    initialConstantSpeed = GameSettings.constantSpeed

    //---Make app object
    app = new PIXI.Application({
        width: GameSettings.viewportWidth, // default: 800
        height: GameSettings.viewportHeight, // default: 600
        antialias: false, // default: false
        backgroundAlpha: true, // default: false
        resolution: GameSettings.viewportScale, // default: 1
        autoStart: true,
    })

    window['globalThis']['__PIXI_APP__'] = app;

    //---Set FPS for testing here
    // app.ticker.minFPS = 10
    // app.ticker.maxFPS = 20

    TrackPath.setupRoadData()

    //Load collision maps
    TrackCollision.initCollisionMaps()

    app.view.width = window.innerWidth
    app.view.height = window.innerHeight

    loadingContainer = new PIXI.Container()
    app.stage.addChild(loadingContainer)

    //--- Show Loading Screen
    LoadingScreen.setUI(app, loadingContainer, () => {
        pixiContainer.appendChild(app.view)

        canvas = document.getElementById('pixiContainer').children[1]
        resize()

        loadingInterval = setInterval(() => {
            let totalPercentage =
                (soundEngine.percentageLoaded +
                    TexturePackerLoader.percentageLoaded) /
                2

            if (Math.round(totalPercentage) >= 100) {
                clearInterval(loadingInterval)
            }

            // console.log(
            //     soundEngine.percentageLoaded,
            //     TexturePackerLoader.percentageLoaded
            // )
            LoadingScreen.setPercentage(totalPercentage)
        }, 1000)
    })

    //--- Load font first
    const loader = new PIXI.Loader()
    loader
        .add('RetroGaming', 'assets/fonts/RetroGaming.xml')
        .add('RetroGamingRegular', 'assets/fonts/RetroGamingRegular.xml')
        .load(() => {
            //---Load texturepacker sheets
            TexturePackerLoader.initSpriteSheets(onSpriteSheetsLoaded)
        })

    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST
    PIXI.settings.MIPMAP_TEXTURES = PIXI.OFF
}

//---Called after sprite sheets are loaded in
export function onSpriteSheetsLoaded() {
    //send('allLoaded')

    //---Create white tex
    const bigWhiteTexture = new PIXI.Texture(PIXI.Texture.WHITE.baseTexture)
    bigWhiteTexture.orig.width = 25
    bigWhiteTexture.orig.height = 25

    //---World container that holds all game scene objects, no UI
    gameSceneContainer = new PIXI.Container()
    gameSceneContainer.filters = []

    //---Create Camera for 3D projection
    camera = new PIXI.projection.Camera3d()
    camera.position.set(app.screen.width / 2, app.screen.height / 2)
    camera.setPlanes(GameSettings.camFov, 0.0001, 10000, false)
    camera.euler.x = GameSettings.camAngle * Hf.deg2Rad
    camera.scale3d.set(GameSettings.camZoom / 1000)

    //---Make 3d containers, child of camera
    const worldContainer = new PIXI.projection.Container3d()
    const objectContainer = new PIXI.projection.Container3d()
    const carContainer = new PIXI.projection.Container3d()
    uiContainer = new PIXI.Container()

    //---Load ground plane
    groundPlane = new PIXI.projection.Sprite3d(bigWhiteTexture)
    groundPlane.anchor.y = 1
    groundPlane.width = 205000
    groundPlane.height = 133700
    groundPlane.tint = 0x44763a

    groundPlane.euler.x = Math.PI //Flat on ground

    //---Load car sprite
    carTex = TexturePackerLoader.textureSheet['car_1_1.png']
    carGhostTex = TexturePackerLoader.textureSheet['car_2_1.png']

    //--Car 3D
    carSprite = new PIXI.projection.Sprite3d(carTex)
    carSprite.anchor.x = 0.5
    carSprite.anchor.y = 1
    carScale = Hf.getScale(carTex, GameSettings.carScale)
    carSprite.scale3d.set(carScale)
    carSprite.euler.x = -Math.PI / 2

    


    carGhost = new PIXI.projection.Sprite3d(carGhostTex)
    carGhost.anchor.x = 0.5
    carGhost.anchor.y = 1
    carGhostScale = Hf.getScale(carGhostTex, GameSettings.carScale)
    carGhost.scale3d.set(carScale)
    carGhost.euler.x = -Math.PI / 2
    carGhost.alpha = 0.4;

    carShadowSprite = new PIXI.projection.Sprite3d(carTex)
    carShadowSprite.anchor.x = 0.5
    carShadowSprite.anchor.y = 1
    carShadowSprite.scale3d.set(carScale)
    carShadowSprite.euler.x = -Math.PI / 2
    carShadowSprite.tint = 0x000000
    carShadowSprite.alpha = 0.5

    carShadowSprite.scale3d.z *= 0.9
    carShadowSprite.position3d.z = -0.25

    //---DEBUG sprites
    debugSpriteA = new PIXI.projection.Sprite3d(bigWhiteTexture)
    debugSpriteA.tint = 0xff00d7
    debugSpriteA.anchor.set(0.5)
    debugSpriteA.scale3d.set(0.1)

    debugSpriteB = new PIXI.projection.Sprite3d(bigWhiteTexture)
    debugSpriteB.tint = 0xffffff
    debugSpriteB.anchor.set(0.5)
    debugSpriteB.scale3d.set(0.2)

    //---Stage elements

    //---Init tile renderer
    initTiles(worldContainer, objectContainer, carSprite)

    //app.stage.addChild(layer)
    app.stage.addChild(gameSceneContainer)
    app.stage.addChild(uiContainer)
    app.stage.setChildIndex(loadingContainer, app.stage.children.length - 1)

    //Init background first
    initBackground(gameSceneContainer)

    gameSceneContainer.addChild(camera)
    gameSceneContainer.addChild(ParticleContainer.initParticles())

    camera.addChild(groundPlane)
    camera.addChild(worldContainer)
    camera.addChild(objectContainer)
    camera.addChild(carContainer)

    carContainer.addChild(debugSpriteB)
    carContainer.addChild(debugSpriteA)
    carContainer.addChild(carShadowSprite)
    carContainer.addChild(carSprite)
    carContainer.addChild(carGhost)

    GameUI.createUI(
        false,
        app,
        soundEngine,
        uiContainer,
        onStartCallback,
        pause,
        resume,
        skipToSegment,
        DrsManager.allowEnableDrs,
        pixelAnimation,
        onPlayAgain,
        forceGameOverScreen,
        resetPauseSpeed
    )

    InputManager.Init()

    PerformanceManager.initPerformanceManager(app, resize)

    //---Init drs stuff
    drsZoomBlurFilter = new ZoomBlurFilter()
    drsZoomBlurFilter.strength = 0
    drsZoomBlurFilter.innerRadius = 50
    DrsManager.initDrs() //Get DRS from server and set UI value

    //---Bind update loop
    if (!appTickerAdded) {
        app.ticker.add(GameUI.uiUpdateLoop)
        app.ticker.add(updateFunction)
        appTickerAdded = true
    }

    //---DEBUG skip ahead to given segment index
    skipToSegment(GameSettings.segmentToStartAt)

    //---Startup minimap
    if (GameSettings.enableMinimap) {
        let minimapContainer = Minimap.SetupMinimap(app, camera)
        app.stage.addChild(minimapContainer)
    }

    //---Bind sliders and values from html
    //bindDebugElements()

    speed = GameSettings.constantSpeed
    cachedLoc = TrackPath.getLocationOnCurve(0, 1, true)
    startLoc = TrackPath.getLocationOnCurve(0, 1, true);
    nearestTrackLoc = cachedLoc

    //--- Set canvas size. Maybe not the best way but this seems to work.
    canvas = document.getElementById('pixiContainer').children[1]
    resize()

    //bitmapData = TrackCollision.bitmaps[1]

    return pixiContainer
}

function updateFunction(delta) {
    Hf.checkOnDemandDebugger()
    Update(delta)
    //GameUI.setSpeedText(speed) Already done in Update()
}

let bitmapData

function setInitialValues() {
    //console.log('Set initial')
    TrackPath.resetAllValues()

    speed = 0

    cachedLoc = TrackPath.getLocationOnCurve(0, 1, true)
    nearestTrackLoc = cachedLoc

    //---Init heading history
    let rad = -Hf.getAngleFromVector(cachedLoc.tangent)
    let carHeading = rad - (Math.PI / 2) * Hf.rad2Deg
    for (let i = 0; i < carHeadingHistory.length; i++) {
        carHeadingHistory[i] = carHeading
    }
}

//---------------------------------------------------------------------------
function Update(delta) {
    //---Set loop vars
    deltaTime = app.ticker.deltaMS / 1000
    elapsed += deltaTime;
    if (gameStarted) elapsedFromStart += deltaTime;
    PerformanceManager.checkFrametime(deltaTime)

    //---Update respawn filter
    if (isFadingIn || isFadingOut) {
        UpdateActiveFade(deltaTime)
    }

    //---Update DRS Filters
    if (InputManager.isDrsActive || drsFilterT !== 0) {
        if (gameSceneContainer.filters.length === 0) {
            gameSceneContainer.filters = [drsZoomBlurFilter]
        }
        drsFilterT = UpdateDrsFilter(
            deltaTime,
            GameSettings.drsFilterFadeInTime,
            InputManager.isDrsActive,
            drsFilterT,
            GameSettings.drsFilterMaxStrength
        )
        if (drsFilterT === 0 && gameSceneContainer.filters.length > 0) {
            gameSceneContainer.filters = []
        }
    }

    //---Update start speed boost filter
    if (InputManager.isStartBoosted || startBoostFilterT !== 0) {
        if (gameSceneContainer.filters.length === 0) {
            gameSceneContainer.filters = [drsZoomBlurFilter]
        }
        startBoostFilterT = UpdateDrsFilter(
            deltaTime,
            0.4,
            InputManager.isStartBoosted,
            startBoostFilterT,
            GameSettings.drsFilterMaxStrength / 3
        )
        if (startBoostFilterT === 0 && gameSceneContainer.filters.length > 0) {
            gameSceneContainer.filters = []
        }
    }

    //Clear log
    //logA.innerHTML = ''

    //---Calc curvature angle/size/maxspeed
    let curveInfo = getCurrentCurveInfo()
    let curveRadius = curveInfo.radius
    let curveAngle = curveInfo.angle

    if (!gameStarted && !tutorialStarted) {
        InputManager.checkCountdownThrottle(elapsed)
    } else if (!GameSettings.keepConstantSpeed && !isDead) {
        //---Update speed based on ground below
        let bitmapReading = TrackCollision.worldPosToBitmapValue(
            cachedLoc.pos.x,
            cachedLoc.pos.y
        )
        if (!bitmapReading) {
            speed = Math.max(
                speed - 9.8 * GameSettings.offroadBrakeGs * deltaTime,
                GameSettings.minOffroadSpeed
            )
        } else {
            //---Update speed based on input
            speed = InputManager.updateSpeed(speed, deltaTime)
        }
    }

    let speedMs = speed / 3.6 //Get speed in meters per second

    //---Calc smallest possible turn radius for current speed, eg: sharpest turn possible
    let smallestTurnRadius = getMinCurveRadius(speed)

    // logA.innerHTML += `R: ${curveRadius.toFixed(
    //     0
    // )} S: ${smallestTurnRadius.toFixed(0)}`

    //---Get next location info based on speed and curve
    let nextLoc
    if (GameSettings.enableCurveSpeedLimits) {
        let nextLocInfo = TrackPath.getNextLocation(
            speedMs,
            curveRadius,
            smallestTurnRadius,
            deltaTime,
            isOnTrack,
            nearestTrackLoc,
            cachedLoc,
            logA
        )
        nextLoc = nextLocInfo.nextLoc
        cachedLoc = nextLocInfo.cachedLoc
        nearestTrackLoc = nextLocInfo.nearestTrackLoc
        isOnTrack = nextLocInfo.isOnTrack

        if (nextLocInfo.isDead) {
            speed = 0
            if (!isDead) {
                Hf.sendInternalEvent('playSample', { sample: 'crash' })
            }

            startRespawnAnimation(nearestTrackLoc)

        


        } else if (nextLocInfo.isOOB) {
            if (!isDead) {
                Hf.sendInternalEvent('playSample', { sample: 'transition' })
                //keep driving while respawn plays
                startRespawnAnimation(nearestTrackLoc)
            }
        }
    } else {
        nextLoc = TrackPath.getLocationOnCurve(speedMs, deltaTime) //km/h to m/s
        cachedLoc = nextLoc
    }

    if (
        nextLoc.tempIndex === 0 &&
        gameStarted &&
        passedStart &&
        !gameOverSent
    ) {
        //Finished!
        onFinishedLap()
    }

    if (!passedStart && TrackPath.currentIndex > 0) {
        passedStart = true
    }

    //---Set cam/car pos
    setCarCamPosition(nextLoc)
    let hObj = {
        time:elapsedFromStart,
        x:nextLoc.pos.x,
        y:nextLoc.pos.y
    }
    
    // console.log(elapsed, nextLoc)


    //---Rotate background
    let rad = -Hf.getAngleFromVector(nextLoc.tangent)

    //---Rotate all world object billboards
    let carRot = rad - Math.PI / 2
    WorldObjectController.rotateBillboards(carRot)

    //---Camera rotation lerping
    setCameraRotation(carRot, deltaTime, curveRadius, speedMs, curveAngle, rad, hObj)

    //---Update UI
    GameUI.setSpeedText(speed)

    ParticleContainer.updateParticles(deltaTime, speed)

    calculateSpeedometerValues(deltaTime)

    //---DEBUG
    tryRenderMinimap(nextLoc, -rad, curveRadius, carSprite.position3d)
    //setDebugValues(nextLoc)
}

function getPosByTime(time) {
    let startObj
    const replay = GlobalData.bestTimeGameReplay;
    if (replay) {
        for (let i = 0; i < replay.length; i++) {
            const obj = replay[i];
            if (time < obj.time) {
                startObj = i > 0 ? replay[i - 1] : replay[0];
                break;
                
            }
    
            if (i == replay.length - 1) startObj = replay[replay.length - 1];
    
        }
    }


    

    if (startObj === undefined) startObj = {x:738.9773559570312, y:1132.265048828125};
    return startObj
}

//--------------------------------------------------------------------------

function outputResult(hist) {
    let reportStr = "";
    reportStr += "class Replay{}\n\n";
    reportStr += "Replay.hist = [];\n";
    for (let i = 0; i < hist.length; i++) {
        const obj = hist[i];
        reportStr += `Replay.hist.push({time:${obj.time}, x:${obj.x}, y:${obj.y}, frm:${obj.frm}});\n`;
        
    }
    reportStr += "\n\n\nexport default Replay";
    console.log(reportStr);
}

function onFinishedLap() {
    elapsedFromStart = 0.0;
    Hf.sendInternalEvent('stopSample', { sample: 'music' })
    Hf.sendInternalEvent('playSample', { sample: 'endscreen' })
    Hf.sendInternalEvent('playSample', { sample: 'finish' })

    //console.log('Overlay Shown')
    gameOverSent = true

    finishTime = GameUI.stopTimer()
    // let bestTimeGameReplay = GlobalData.bestTime === 0 || finishTime < GlobalData.bestTime ? history : undefined
    let bestTimeGameReplay = undefined

    if (GlobalData.bestTime === 0  || finishTime < GlobalData.bestTime) {
        GlobalData.bestTime = finishTime
        bestTimeGameReplay = history

        GlobalData.bestTimeGameReplay = bestTimeGameReplay;

        if (bestTimeGameReplay.length > GameSettings.maxReplayLength) {
            GlobalData.bestTimeGameReplay = bestTimeGameReplay.slice(0, GameSettings.maxReplayLength);
        }


    }
    

    Hf.send('game-end', {
        action: 'game-end',
        results: {
            customerId: GlobalData.customerId,
            drsUsed: DrsManager.getDrsUsed(),
            time: finishTime,
            gameData: GameMetrics.getGameData(), // Checks whether person cheated or not
            bestTimeGameReplay:bestTimeGameReplay,
            bestTime: GlobalData.bestTime            
        },
    })

    //outputResult(history);


}

export function pixelAnimation() {
    doCarLocationReset = false

    //Pixelate in
    respawnFilter = new PixelateFilter()
    app.stage.filters = [respawnFilter]
    respawnFilter.size = 1

    isFadingIn = true
}

function animatePixelIn(callback) {
    fadeT += deltaTime / GameSettings.respawnFadeTime

    if (fadeT > 1) {
        fadeT = 1
        speed = 0
        isFadingIn = false
        isFadingOut = true

        if (callback) {
            callback()
        }

        // Exception for when tutorial has started and this function is called
        if (teleportToStart) {
            onPlayAgain()
            teleportToStart = false
            doCarLocationReset = true
            DrsManager.setInitialDrs()

            // So you can't start with gas. No cheats. You shall not gas.
            InputManager.forceGasPressRelease()
        }
    }

    let scaledValue = Hf.lerp(1, 100, fadeT * fadeT)
    //scaledValue = Math.ceil(Math.log(scaledValue) / Math.log(2))
    scaledValue = Math.max(1, scaledValue)
    respawnFilter.size = scaledValue
}

function animatePixelOut(callback) {
    fadeT -= deltaTime / GameSettings.respawnFadeTime

    if (fadeT < 0) {
        fadeT = 0
        isFadingOut = false
        app.stage.filters = []
        callback()
    }

    let scaledValue = Hf.lerp(1, 100, fadeT * fadeT)
    //scaledValue = Math.ceil(Math.log(scaledValue) / Math.log(2))
    scaledValue = Math.max(1, scaledValue)
    respawnFilter.size = scaledValue
}

function startRespawnAnimation(newCarLosArg) {
    if (isDead) {
        return
    }

    InputManager.deactivateDrs()

    //Pixelate in
    respawnFilter = new PixelateFilter()
    app.stage.filters = [respawnFilter]
    respawnFilter.size = 1

    isFadingIn = true
    isDead = true
    newCarLoc = newCarLosArg
}

function UpdateActiveFade() {
    if (isFadingIn) {
        if (!doCarLocationReset) {
            animatePixelIn()
        } else {
            animatePixelIn(() => {
                cachedLoc = newCarLoc
                isOnTrack = true
            })
        }
    } else if (isFadingOut) {
        animatePixelOut(() => {
            isDead = false
        })
    }
}

function UpdateDrsFilter(deltaTime, fadeTime, isIncreasing, t, maxStrength) {
    //---Get T value
    let stepSize = deltaTime / fadeTime
    t += isIncreasing ? stepSize : -stepSize
    t = Math.min(t, 1)
    t = Math.max(t, 0)

    //---Set filter strength
    let scaledT = t * t * t //Square to increase less at start
    let filterStrength = Hf.lerp(0, maxStrength, scaledT)
    drsZoomBlurFilter.strength = filterStrength

    const centerHeight = 0.65
    let xPos = GameSettings.currentViewportWidth / 2
    let yPos = (GameSettings.currentViewportHeight / 1) * centerHeight
    if (GameSettings.isPortrait) {
        yPos = GameSettings.currentViewportHeight - yPos
    }
    drsZoomBlurFilter.center.x = GameSettings.isPortrait ? yPos : xPos
    drsZoomBlurFilter.center.y = GameSettings.isPortrait ? xPos : yPos

    //---Change FOV
    let fovT = 1 + t * 0.6
    let newFov = GameSettings.camFov / fovT
    camera.setPlanes(newFov, 0.0001, 10000, false) //First param affects FOV

    return t
}

function tryRenderMinimap(nextLoc, rad, curveRadius, carPos) {
    if (GameSettings.enableMinimap) {
        //---Minimap
        //Temp fill values
        let offsetVector = new PIXI.Point(0, 0)
        let nextLocWithoutOffset = nextLoc
        let rad2 = rad

        Minimap.DrawMinimap(
            nextLoc,
            rad,
            curveRadius,
            carPos,
            offsetVector,
            nextLocWithoutOffset,
            rad2
        )
    }
}

//---Sets Camera and Car position
function setCarCamPosition(nextLoc) {
    let camBackOffset = nextLoc.tangent
    camBackOffset = camBackOffset.multiplyScalar(-GameSettings.camDistBack)
    let camPos = nextLoc.pos.add(camBackOffset)

    camera.position3d.x = camPos.x
    camera.position3d.y = camPos.y
    camera.position3d.z = GameSettings.camHeight

    carSprite.position3d.x = nextLoc.pos.x
    carSprite.position3d.y = nextLoc.pos.y

    if (GlobalData.bestTimeGameReplay && GlobalData.bestTimeGameReplay.length > 0 ) {
        gohostCrrPos = getPosByTime(elapsedFromStart);
        carGhost.position3d.x = gohostCrrPos.x;
        carGhost.position3d.y = gohostCrrPos.y;
        carGhost.alpha = 0.4;
    } else {
        carGhost.alpha = 0;
    }


    carShadowSprite.position3d.x = nextLoc.pos.x
    carShadowSprite.position3d.y = nextLoc.pos.y
}

//---Gets current curvature from roadCurveLut lookup table
function getCurrentCurveInfo() {
    //Get index before and after current pos
    let curveLutIndexA = Math.floor(
        TrackPath.currentTotalDistance / TrackPath.curveLutDistStep
    )
    let curveLutIndexB = Math.ceil(
        TrackPath.currentTotalDistance / TrackPath.curveLutDistStep
    )
    //Get angles for both indexes
    let angleA = Hf.getElementAt(TrackPath.roadCurveLut, curveLutIndexA)
    let angleB = Hf.getElementAt(TrackPath.roadCurveLut, curveLutIndexB)
    let t =
        (TrackPath.currentTotalDistance % TrackPath.curveLutDistStep) /
        TrackPath.curveLutDistStep
    //Lerp between angle behind and in front to get current angle0
    let angle = Hf.lerp(angleA, angleB, t)

    return {
        radius: (360 * TrackPath.curveLutDistStep) / (2 * Math.PI * angle),
        angle: angle,
    }
}

//---Gets smallest turn radius for current speed
function getMinCurveRadius(speed) {
    let minRadius = (0.1 * Math.pow(speed, 4) + 30) / 3000000 + speed * 0.1 + 40

    return minRadius
}

//---Sets camera + car rotation, also sets car animation state, also updates background rotation
function setCameraRotation(
    carRot, //World rotation in Radians
    deltaTime, //Frame time in s
    curveRadius, //Current turn curvature radius
    speedMs, //Speed in meters / sec
    curveAngle, //Angle of current curve, for lenth of TrackPath.curveLutDistStep (5)
    rad,
    hObj
) {
    let deltaDist = speedMs * deltaTime
    //Gets angle on curve, angle is per meter
    let angle = Hf.getCentralAngle2(curveRadius, speedMs)

    //---Cycle car wheel anim based on speed

    let maxSpeedMs = GameSettings.maxSpeed / 3.6
    let speedScalar = Math.min(speedMs / maxSpeedMs, 1) //0-1 to maxSpeed / 3, clamped at 1,
    if (
        elapsed >
        lastCarWheelAnimCycle + GameSettings.minCarWheelAnimDelay / speedScalar
    ) {
        lastCarWheelAnimCycle = elapsed
        carWheelAnimCycle = !carWheelAnimCycle
    }

    //---Get current car sprite side perspective
    const spriteSwitchAngle = 41500 / 10000 //tempSpriteSwitchAngle //0.001
    carAnimIndex = 0
    for (let i = 7; i >= 0; i--) {
        if (angle > spriteSwitchAngle * i) {
            carAnimIndex = i

            break
        }
    }

    hObj.frm = carAnimIndex;
    history.push(hObj);

    //setCarSprites(tempCarAngle)
    setCarSprites(carAnimIndex)

    //---Check if car has switched direction,
    // There can be jumps right after segment switch, so check with heading history
    let hasSwitched = true
    let carRotInDegrees = carRot * Hf.rad2Deg
    if (carRot == lastCarRot) {
    } else if (carHeadingLeft) {
        for (let i = 0; i < carHeadingHistory.length; i++) {
            if (Hf.AngleDifference(carRotInDegrees, carHeadingHistory[i]) < 0) {
                hasSwitched = false
                break
            }
        }
        if (hasSwitched) {
            carHeadingLeft = false
        }
    } else {
        for (let i = 0; i < carHeadingHistory.length; i++) {
            if (Hf.AngleDifference(carRotInDegrees, carHeadingHistory[i]) > 0) {
                hasSwitched = false
                break
            }
        }
        if (hasSwitched) {
            carHeadingLeft = true
        }
    }

    //---Set camera tilt and mirror car
    //const tiltMult = 0.0 //0.03
    //let tilt = 0
    if (carHeadingLeft) {
        // if (!tempMirrored) {
        //     debugger
        // }
        // tempMirrored = true

        carSprite.scale3d.x = -carScale
        carGhost.scale3d.x = -carScale
        //tilt = -angle * tiltMult - Math.PI

        carShadowSprite.scale3d.x = -carScale

        camera.euler.z = -carRot //+ angle

        ParticleContainer.setParticlePos(
            width - carAnimIndex * 8,
            GameSettings.viewportHeight
        )
    } else {
        // if (tempMirrored) {
        //     debugger
        // }
        // tempMirrored = false

        carSprite.scale3d.x = carScale
        carGhost.scale3d.x = carScale
        //tilt = angle * tiltMult - Math.PI

        carShadowSprite.scale3d.x = carScale

        camera.euler.z = -carRot //+ angle * -1

        ParticleContainer.setParticlePos(
            width + carAnimIndex * 8,
            GameSettings.viewportHeight
        )
    }

    //---Set carHeadingHistory
    carHeadingHistory[carHeadingHistoryIndex] = carRotInDegrees
    carHeadingHistoryIndex =
        (carHeadingHistoryIndex + 1) % carHeadingHistory.length

    //---Set camera rotation
    camera.euler.y = Math.PI
    updateBackground(rad)

    //---Set car rotation
    carSprite.euler.y = carRot - Math.PI
    carGhost.euler.y = carRot - Math.PI
    carShadowSprite.euler.y = carRot - Math.PI

    //Cache car rot
    lastCarRot = carRot
}

let carHeadingLeft = false
let carHeadingHistoryIndex = 0
let carHeadingHistory = [0, 0]
let tempMirrored = false

function getCarTextureByIndex(index) {
    let spriteName =
    'car_' + (index + 1) + '_' + (carWheelAnimCycle ? 1 : 2) + '.png'
    let newTexture = TexturePackerLoader.textureSheet[spriteName]
    return newTexture
}

//---Set car+shadow sprites based on given index (0-7)
function setCarSprites(carAnimIndex) {
    //---Get current sprite based on rotation and wheel anim state
    // let spriteName = 'car_' + (carAnimIndex + 1) + '_' + (carWheelAnimCycle ? 1 : 2) + '.png'
    // let newTexture = TexturePackerLoader.textureSheet[spriteName]
    let newTexture = getCarTextureByIndex(carAnimIndex);
    let newGhostTexture = gohostCrrPos ? getCarTextureByIndex(gohostCrrPos.frm) : undefined;

    //---Scale up car with higher turn %, the sprites are smaller
    const maxExtrScalePercent = 15
    carScale = Hf.getScale(newTexture, GameSettings.carScale)
    let extraScale = (maxExtrScalePercent / 8) * carAnimIndex
    //Manual adjust for last sprite as it differs a lot
    if (carAnimIndex == 7) {
        extraScale = 22
    }
    carScale *= 1 + extraScale / 100
    carSprite.scale3d.set(carScale)
    carGhost.scale3d.set(carScale)
    carShadowSprite.scale3d.set(carScale)

    //Set car textures
    carSprite.texture = newTexture
    carShadowSprite.texture = newTexture
    if (newGhostTexture) carGhost.texture = newGhostTexture;
}

//---Skips segments to start at higher index without breaking things
export function skipToSegment(startIndex) {
    for (let i = 0; i < startIndex; i++) {
        let segment = Hf.getElementAt(TrackPath.roadPoints, i)
        let distToSkip = segment.lut[segment.lut.length - 1]
        TrackPath.getLocationOnCurve(distToSkip, 1)
    }
}

//---Binds html elements for debugging
function bindDebugElements() {
    const numSliders = GameSettings.sliderSettings.length
    let sliderPrefix = 'slider'

    //---Bind all sliders
    for (let i = 0; i < numSliders; i++) {
        let sliderSetting = GameSettings.sliderSettings[i]

        //---Bind HTMl elements
        sliderValues.push(sliderSetting.default)
        sliderElements.push(document.getElementById(sliderPrefix + i))
        sliderTooltips.push(
            document.getElementById(sliderPrefix + i + 'Tooltip')
        )

        //---Set HTML values
        sliderTooltips[i].innerHTML =
            sliderSetting.name + ': ' + sliderSetting.default
        sliderElements[i].min = sliderSetting.min
        sliderElements[i].max = sliderSetting.max
        sliderElements[i].value = sliderSetting.default

        let index = i
        sliderElements[i].oninput = function () {
            sliderValues[index] = this.value
            sliderTooltips[index].innerHTML =
                sliderSetting.name + ': ' + this.value
        }
    }

    //---Bind log A
    logA = document.getElementById('logA')
}

let tempCarAngle = 0

//---Set internal values from HTML sliders + fill logA
function setDebugValues(nextLoc) {
    tempCarAngle = Number(sliderValues[0])

    logA.innerHTML += `Car pos: x: ${nextLoc.pos.x.toFixed(
        0
    )} y: ${nextLoc.pos.y.toFixed(0)}
    , Segment: ${TrackPath.currentIndex}`
}

let speedometerT = 0
const speedometerTime = 0.3

function calculateSpeedometerValues(deltaTime) {
    if (InputManager.isGassing || InputManager.isDrsActive) {
        speedometerT += deltaTime / speedometerTime
    } else {
        speedometerT -= deltaTime / speedometerTime
    }
    speedometerT = Math.min(1, speedometerT)
    speedometerT = Math.max(0, speedometerT)

    // Not food
    let percentageBasedOnTachos
    if (speed != 0) {
        percentageBasedOnTachos =
            ((speed % ((GameSettings.maxSpeed + 0.1) / 6)) /
                ((GameSettings.maxSpeed + 0.1) / 6)) *
            100

        percentageBasedOnTachos =
            percentageBasedOnTachos * 0.4 +
            percentageBasedOnTachos * 0.6 * speedometerT
    } else {
        //If not moving (Countdown), rev up
        percentageBasedOnTachos = speedometerT * 75
    }

    GameUI.setSpeedBlockValue(percentageBasedOnTachos)
}

function onStartCallback() {
    elapsed = 0.0;
    elapsedFromStart = 0.0;
    gameStarted = true
    countDownDone = true
    DrsManager.allowEnableDrs(GameSettings.drsCooldown) //only enable after cooldown so no using during start
    InputManager.onGameStart(elapsed)
    GameMetrics.resetMetrics()
}

window.addEventListener('resize', (event) => {
    resize()
})

function resize() {
    let height = GameSettings.viewportHeight
    if (!canvas) {
        canvas = document.getElementById('pixiContainer').children[1]
    }

    canvas.style = `width: 100%; height: ${window.innerHeight}px`

    if (window.innerWidth < window.innerHeight) {
        width =
            GameSettings.viewportHeight *
            ((GameSettings.useDebugCanvas
                ? canvas.clientHeight
                : window.innerHeight) /
                window.innerWidth)
        //canvas.style = `width: 100vh; height: 100vw; transform: rotate(90deg); transform-origin: bottom left; top: -100vw; position: absolute;`

        app.stage.position.set(GameSettings.viewportHeight / 2, width / 2)
        app.stage.rotation = Math.PI / 2
        app.stage.pivot.set(width / 2, GameSettings.viewportHeight / 2)
        app.renderer.resize(GameSettings.viewportHeight, width)

        GameSettings.currentViewportWidth = width
        GameSettings.currentViewportHeight = GameSettings.viewportHeight
        GameSettings.isPortrait = true

        if (!camera) return

        camera.position.set(
            app.renderer.screen.height / 2,
            app.renderer.screen.width / 2
        )
        Hf.send('portrait')
    } else {
        width =
            GameSettings.viewportHeight *
            (window.innerWidth /
                (GameSettings.useDebugCanvas
                    ? canvas.clientHeight
                    : window.innerHeight))
        app.stage.position.set(0, 0)
        app.stage.pivot.set(0.5, 0.5)
        app.stage.rotation = 0

        app.renderer.resize(width, GameSettings.viewportHeight)

        GameSettings.currentViewportWidth = width
        GameSettings.currentViewportHeight = GameSettings.viewportHeight
        GameSettings.isPortrait = false

        if (!camera) return

        camera.position.set(
            app.renderer.screen.width / 2,
            app.renderer.screen.height / 2
        )
        Hf.send('resize')
    }
}

//This 'play again' is not full restart and doesn't show buttons but just continuie the race
function onPlayAgain() {
    Hf.send('game-start', {
        action: 'game-start',
    })
    GameUI.setUiState('game')

    // Reset values for game over overlay
    gameStarted = false
    passedStart = false
    gameOverShown = false
    countDownDone = false
    tutorialStarted = false
    didNotFinish = false
    gameOverSent = false

    DrsManager.disAllowEnableDrs()
    //GameUI.setDRSInteractive(false)

    // Force timer to stop and reset
    GameUI.stopTimer()
    GameUI.resetTimer()

    Hf.sendInternalEvent('playSample', { sample: 'music' })
    Hf.sendInternalEvent('stopSample', { sample: 'endscreen' })

    // Not allowed to use brake or gas even when it was pressed before transition
    updateSpeed(0, deltaTime)
    GameUI.setInteractiveManual(false, true) // You can now gas on start

    // Start at segment 0
    setInitialValues()
    DrsManager.setInitialDrs()
    InputManager.setInitial()

    // Unload everything and reload only the ones needed
    WorldObjectController.resetWorldObjects()

    if (GlobalData.personalRecord !== 0) {
        GameUI.setBestLapText(GlobalData.personalRecord, true, true)
    }

    // Reset values when playing again.
    GameSettings.constantSpeed = initialConstantSpeed
    GameSettings.keepConstantSpeed = false

    // Set speed to 0
    speed = 0
    pausedSpeed = 0

    // Traffic lights animation
    WorldObjectController.doTrafficLightsAnimation(() => {
        onStartCallback()
        GameUI.startTimer()
        GameUI.setInteractive(true)
        countDownDone = true
    })

}

function beforeResetToStart() {
    /*
    gameStarted = false
    passedStart = false
    gameOverShown = false
    countDownDone = false
    tutorialStarted = false
    didNotFinish = false
    gameOverSent = false

    DrsManager.disAllowEnableDrs()
    //GameUI.setDRSInteractive(false)

    // Force timer to stop and reset
    GameUI.stopTimer()
    GameUI.resetTimer()

    

    if (GlobalData.personalRecord !== 0) {
        GameUI.setBestLapText(GlobalData.personalRecord, true, true)
    }

    GameSettings.constantSpeed = initialConstantSpeed
    GameSettings.keepConstantSpeed = false

    // Set speed to 0
    speed = 0
    pausedSpeed = 0 */


    Hf.send('game-start', {
        action: 'game-start',
    })
    GameUI.setUiState('game')

    // Reset values for game over overlay
    gameStarted = false
    passedStart = false
    gameOverShown = false
    countDownDone = false
    tutorialStarted = false
    didNotFinish = false
    gameOverSent = false

    DrsManager.disAllowEnableDrs()
    //GameUI.setDRSInteractive(false)

    // Force timer to stop and reset
    GameUI.stopTimer()
    GameUI.resetTimer()

    Hf.sendInternalEvent('stopSample', { sample: 'music' })
    Hf.sendInternalEvent('stopSample', { sample: 'endscreen' })

    // Not allowed to use brake or gas even when it was pressed before transition
    updateSpeed(0, deltaTime)
    GameUI.setInteractiveManual(false, true) // You can now gas on start

    // Start at segment 0
    setInitialValues()
    DrsManager.setInitialDrs()
    InputManager.setInitial()

    // Unload everything and reload only the ones needed
    WorldObjectController.resetWorldObjects()

    if (GlobalData.personalRecord !== 0) {
        GameUI.setBestLapText(GlobalData.personalRecord, true, true)
    }

    // Reset values when playing again.
    GameSettings.constantSpeed = initialConstantSpeed
    GameSettings.keepConstantSpeed = false

    // Set speed to 0
    speed = 0
    pausedSpeed = 0

    // Traffic lights animation
    /*
    WorldObjectController.doTrafficLightsAnimation(() => {
        onStartCallback()
        GameUI.startTimer()
        GameUI.setInteractive(true)
        countDownDone = true
    }) */


    /*
    Hf.send('game-start', {
        action: 'game-start',
    })
    GameUI.setUiState('game')

    // Reset values for game over overlay
    gameStarted = false
    passedStart = false
    gameOverShown = false
    countDownDone = false
    tutorialStarted = false
    didNotFinish = false
    gameOverSent = false

    DrsManager.disAllowEnableDrs()
    //GameUI.setDRSInteractive(false)

    // Force timer to stop and reset
    GameUI.stopTimer()
    GameUI.resetTimer()

    Hf.sendInternalEvent('playSample', { sample: 'music' })
    Hf.sendInternalEvent('stopSample', { sample: 'endscreen' })

    // Not allowed to use brake or gas even when it was pressed before transition
    updateSpeed(0, deltaTime)
    GameUI.setInteractiveManual(false, true) // You can now gas on start

    // Start at segment 0
    setInitialValues()
    DrsManager.setInitialDrs()
    InputManager.setInitial()

    // Unload everything and reload only the ones needed
    WorldObjectController.resetWorldObjects()

    if (GlobalData.personalRecord !== 0) {
        GameUI.setBestLapText(GlobalData.personalRecord, true, true)
    }

    // Reset values when playing again.
    GameSettings.constantSpeed = initialConstantSpeed
    GameSettings.keepConstantSpeed = false

    // Set speed to 0
    speed = 0
    pausedSpeed = 0

    // Traffic lights animation
    WorldObjectController.doTrafficLightsAnimation(() => {
        onStartCallback()
        GameUI.startTimer()
        GameUI.setInteractive(true)
        countDownDone = true
    })


    */
}

function resetToStart() {
    TrackPath.setCurrentProgressValues(0, 0, 0, 0);
    beforeResetToStart();

    Hf.sendInternalEvent('stopSample', { sample: 'music' });
    Hf.sendInternalEvent('stopSample', { sample: 'endscreen' });
    Hf.sendInternalEvent('stopSample', { sample: 'finish' });

    // onPlayAgain();

}

export function pause() {
    GameSettings.isPaused = true

    Hf.sendInternalEvent('stopSample', { sample: 'engine2' })

    if (tutorialVisible) {
        GameUI.setInteractive(false)
        return
    }

    pausedSpeed = speed

    app.stage.filters = []

    if (appTickerAdded) {
        app.ticker.remove(updateFunction)
        appTickerAdded = false
    }

    GameUI.pauseTimer()

    // Exception, because you can now gas in countdown

    GameUI.setInteractive(false)
    GameUI.setDRSInteractive(false)
    GameUI.setSpeedText(pausedSpeed)
}

export function resume(
    startTimer = true,
    throughMenu = false,
    resumeThroughQuit = false
) {
    GameSettings.isPaused = false

    if (throughMenu && tutorialVisible) {
        GameUI.setInteractiveManual(canBrakeTutorial, canGasTutorial)

        // This exception, because if you quit in when the tutorial is visible and the game is paused, you do want to add the app ticker.
        if (!resumeThroughQuit) {
            return
        }
    }

    if (InputManager.isDrsAllowed || canDrsTutorial) {
        DrsManager.allowEnableDrs()
    }

    if (isFadingIn || isFadingOut) {
        app.stage.filters = [respawnFilter]
    }

    if (!appTickerAdded) {
        app.ticker.add(updateFunction)
        appTickerAdded = true
    }

    // We don't want to keep the speed if we resumed through quitting.
    speed = pausedSpeed

    if (startTimer && countDownDone) {
        GameUI.startTimer()
    }

    GameUI.setInteractive(true)
}

window.addEventListener('message', processEvent)

function processEvent(obj) {
    console.log('--processevent', obj.data.eventName)
    switch (obj.data.eventName) {
        case 'startTutorial':
            tutorialStarted = true
            countDownDone = true
            break

        case 'teleportToStart':
            teleportToStart = true
            GameUI.stopTimer()
            GameUI.resetTimer()
            break

        case 'showTutorial':
            tutorialVisible = true
            break

        case 'hideTutorial':
            tutorialVisible = false
            break

        case 'dataSet':
            // console.log("Personal record: ", GlobalData.personalRecord)
            onDataSet();

            break

        case 'receivedPrize':
            //console.log('Receive price', gameOverShown)
            if (!gameOverShown) {
                //console.log('Show overlay')
                //showGameOverScreen()
                //GameUI.setUiState('gameOver')
            }
            break

        case 'canGas':
            canDrsTutorial = false
            canBrakeTutorial = false
            canGasTutorial = true
            break

        case 'canBrake':
            canDrsTutorial = false
            canGasTutorial = false
            canBrakeTutorial = true
            break

        case 'canDrs':
            GameUI.setDRSInteractive(true)
            canDrsTutorial = true
            canGasTutorial = false
            canBrakeTutorial = false
            break
        case 'completeTutorial':
            canDrsTutorial = false
            canGasTutorial = false
            canBrakeTutorial = false
            break

        case 'soundLoaded':
            //console.log('Sound is loaded')
            soundLoaded = true
            break

        case 'allLoaded':
            console.log('---allLoaded!')
            //GameUI.showStartOverlay()
            
            break
        case 'allLoadedB':
            //console.log('allLoaded!')
            GameUI.showStartOverlay()

            break
        case 'restartGame':
            startRespawnAnimation(startLoc);
            setTimeout(resetToStart, 800);//We need it to change the position of the car out of the cam
            GameUI.showStartOverlay();
            GameUI.hideUI();
            hideGameOverScreen();
            

            break
    }
}

// For when someone presses quit in-game
export function forceGameOverScreen() {
    Hf.sendInternalEvent('stopSample', { sample: 'music' })
    Hf.sendInternalEvent('playSample', { sample: 'endscreen' })
    Hf.sendInternalEvent('playSample', { sample: 'finish' })

    didNotFinish = true

    
    Hf.send('close', {
        action: 'close',
    })
        

    DrsManager.setInitialDrs()
}

function onDataSet() {
    DrsManager.setInitialDrs()
    // console.log("Personal record: ", GlobalData.personalRecord)
    if (GlobalData.personalRecord !== 0) {
        GameUI.setBestLapText(GlobalData.personalRecord, true, true)
    }
    GameUI.showStartOverlay()
    gameOverSent = false;
}

function showGameOverScreen() {
    gameOverShown = true

    GameMetrics.resetMetrics()
    DrsManager.disAllowEnableDrs()
    GameUI.setDRSInteractive(false)

    let isRecord = false
    if (!didNotFinish) {
        console.log('did not finish')
        GameUI.setBestLapText(finishTime, true, true)
        isRecord =
            finishTime < GlobalData.personalRecord ||
            GlobalData.personalRecord === 0

        if (isRecord) {
            GlobalData.personalRecord = finishTime
        }
    }

    GameUI.resetTimer()
    GameUI.hideUI()
    GameUI.setCloseInteractive(true)
    GameOverOverlay.show(
        app,
        finishTime,
        uiContainer,
        GameUI.getCloseSprite(),
        onPlayAgain,
        () =>
            Hf.send('share', {
                action: 'share',
            }),
        GlobalData.gamesAvailable,
        didNotFinish,
        isRecord
    )

    // In case there was an input, reset those
    InputManager.setInitial()

    // To make the transition in speed a bit smoother
    speed = 150

    // Set constant speed, because it's cool if the car is still moving even when you're done.
    GameSettings.constantSpeed = 50
    GameSettings.keepConstantSpeed = true

    DrsManager.setInitialDrs()
}


function hideGameOverScreen() {
    gameOverShown = false

    // GameMetrics.resetMetrics()
    // DrsManager.disAllowEnableDrs()
    GameUI.setDRSInteractive(true)

    /*
    let isRecord = false
    if (!didNotFinish) {
        console.log('did not finish')
        GameUI.setBestLapText(finishTime, true, true)
        isRecord =
            finishTime < GlobalData.personalRecord ||
            GlobalData.personalRecord === 0

        if (isRecord) {
            GlobalData.personalRecord = finishTime
        }
    }*/

    GameUI.resetTimer()
    GameUI.hideUI()
    GameUI.setCloseInteractive(true)
    GameOverOverlay.hide()

    // In case there was an input, reset those
    InputManager.setInitial()

    // To make the transition in speed a bit smoother
    // speed = 150

    // Set constant speed, because it's cool if the car is still moving even when you're done.
    // GameSettings.constantSpeed = 50
    // GameSettings.keepConstantSpeed = true

    // DrsManager.setInitialDrs()
}

export function resetPauseSpeed() {
    pausedSpeed = 0
}

export function getTutorialStarted() {
    return tutorialStarted
}
